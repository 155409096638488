import { TrackerType } from '@/modules/trackers';
import { TrackerTypeChip } from '@/modules/trackers/components/TrackerTypeChip';
import { ICellRendererParams } from 'ag-grid-community';
import { FunctionComponent } from 'react';
import { isNil } from 'lodash-es';

export interface TrackerTypeCellRendererParams {
  trackerType?: TrackerType | null;
}

interface TrackerTypeCellRendererProps extends ICellRendererParams<unknown>, TrackerTypeCellRendererParams {}

export const TrackerTypeCellRenderer: FunctionComponent<TrackerTypeCellRendererProps> = (props: TrackerTypeCellRendererProps) => {
  return (
    <div className="flex h-full items-center">{!isNil(props.trackerType) ? <TrackerTypeChip trackerType={props.trackerType} /> : null}</div>
  );
};
